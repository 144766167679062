@import './fonts';

.tt-sticky-top {
  @extend .sticky-top;
  z-index: 0;
  top: $calculated-navbar-height-sm;
  @include media-breakpoint-up(md) {
    top: $calculated-navbar-height + 2rem;
  }
}

// body {
//   & > * + * {
//     margin-top: 16px;
//   }
// }

// NEW PRIMARY BTN

// button-variant(background, border-color, hover:background-color, hover:border-color, active:background-color, active:border-color)

.btn {
  //letter-spacing: 1.2px;
  text-transform: uppercase;
}

.btn-primary {
  @include button-variant(
    $yellow,
    $yellow,
    darken($yellow, 10%),
    darken($yellow, 10%),
    lighten($yellow, 10%),
    lighten($yellow, 10%)
  );
}

.btn-primary.btn-lg,
.btn-lg {
  font-weight: bold;
  //font-size: 1rem;
  //letter-spacing: 1.2px;
  //line-height: 100%;
  //padding: 18px 22px 11px;
  text-transform: uppercase;
}

.btn,
.nav-item {
  text-transform: uppercase;
}

.shoppingcart-finisher {
  background-color: rgba($af-black3, 0.8);
}

.sticky-bottom.is-valid.when-valid .btn-primary,
.shoppingcart-finisher .btn-primary {
  transition: all 300ms ease;
  &:hover {
    letter-spacing: 3px;
  }
}

.sticky-bottom.is-valid.when-valid {
  .btn-primary {
    @include button-variant(
      $black,
      $black,
      darken($black, 10%),
      darken($black, 10%),
      lighten($black, 10%),
      lighten($black, 10%)
    );
  }
}

.media-heading {
  font-family: $font-family-sans-serif;
}

body {
  background-color: $primary;
  #main {
    background: $white;
  }
}

body.brand-detail-page,
body.brand-listing-page,
body.category-detail-page,
body.category-listing-page,
body.product-listing-page {
  #main {
    background-color: $white;
    // color: hsla(0, 0%, 100%, 0.6);
  }
  #product-listing {
    //min-height: 50vh;
  }
  .blurry-background-image {
    background-color: transparent !important;
    overflow: hidden;
  }
}

body.product-listing-page {
  //margin-top: -$calculated-navbar-height;
  .maki {
    .card-title {
      line-height: 1;
      color: $primary;
    }
    .sub-title {
      display: none !important;
    }
  }
}

body.brand-listing-page,
body.brand-detail-page,
body.category-detail-page,
body.category-listing-page {
  h1 {
    // background-color: darken($gray-200, 50%);
    //background-color: $gray-100;
    color: $primary;
    //box-shadow: $box-shadow-lg;
    text-align: left;
  }
  .blurry-background-image {
    overflow: hidden;
    background-color: $primary;
  }
  .maki {
    .card-title {
      line-height: 1;
      color: $primary;
    }
    .sub-title {
      display: none !important;
    }
  }
}

body.brand-detail-page {
  h1 {
    background-color: $primary;
    color: $white !important;
  }
}

body.product-detail-page {
  #main {
    background-color: $white;

    //   //background-color: $af-black2;
    //   p {
    //     color: hsla(0, 0%, 100%, 0.6);
    //   }
    //   h1,
    //   h2,
    //   h3,
    //   strong {
    //     color: $white;
    //   }
    // }
    // .container.detail-page {
    //   color: hsla(0, 0%, 100%, 0.6);
    // }
  }
  .carousel-inner {
    //box-shadow: $box-shadow-lg;
    //border-radius: 0.25rem;
    background-color: $white;
  }
  .carousel-item-content img {
    //border-radius: 1rem;
  }
  .carousel-control-prev,
  .carousel-control-next {
    color: $af-yellow;
  }
  .carousel-indicators {
    @include media-breakpoint-down(md) {
      display: none;
    }
    position: relative;
    bottom: -10px;
  }
  .product-thumb {
    background-color: transparent !important;
    border-radius: $border-radius;
    height: 60px;
    width: 60px;
    margin: 5px;
  }
  header {
    // @include media-breakpoint-down(md) {
    //   margin-top: 80px !important;
    // }
  }
  .variant-chooser .variant-chooser-quantity input {
    @extend .mx-2;
  }
  .variant-chooser .variant-chooser-price {
    //color: $white;
    font-size: $h5-font-size;
  }
  .former-price {
    text-decoration: line-through;
  }
}

.product-content {
  h2 {
    font-size: $h4-font-size;
  }
  background: transparent;
  a {
    //color: $white;
  }
  a:hover {
    //color: rgba($white, 30%);
  }
}

#root {
  padding-top: 0;
}

#prefooter {
  background: $af-gray-bg;
  font-size: $font-size-sm;
  box-shadow: $box-shadow-inverse-xl;
  //min-height: 40vh;
  color: #fff;
  line-height: 1.75rem;
  h5 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: $h3-font-size;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
  .copyright {
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
  .copyright-logos {
    img {
      max-height: 2rem;
    }
  }
  .badge {
    color: $dark;
  }
}

#footer {
  background: $af-yellow;
  //font-size: $font-size-sm;
  min-height: 40vh;
  line-height: 1.75rem;
  h5 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: $h3-font-size;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .copyright {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .copyright-logos {
    img {
      max-height: 2rem;
    }
    margin-top: 25px;
  }
}

.headerimage {
  background-size: contain;
  margin-top: 0;
  height: 35vh;
  width: 100vw;
  img {
    width: 80vw;
  }
  @include media-breakpoint-up(md) {
    height: 50vh;
    img {
      width: 50vw;
    }
  }
  @include media-breakpoint-up(lg) {
    height: 70vh;
  }
  // .headerimage-content {
  //   width: 100vw;
  //   @include media-breakpoint-down(sm) {
  //     width: 100vw;
  //     padding: 20px !important;
  //   }
  //   h1 {
  //     color: $white;
  //     @include media-breakpoint-down(sm) {
  //       font-size: $h4-font-size;
  //     }
  //   }
  // }
}

body[data-scrolled] {
  .headerimage {
    opacity: 0.5;
    transition: all 0.2s ease;
    //transform: translateY(0);
  }
}

// .masonry .maki-container {
//   align-items: flex-start;
// }

// .maki.card .card-body {
//   padding: $card-spacer-x;

//   .card-title {
//     @extend .text-truncate;
//     .title {
//       @extend .text-truncate;
//       font-weight: 900;
//     }
//   }
// }

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5,
.title {
  //font-weight: 400 !important;
  text-transform: uppercase;
  font-family: $headings-font-family;
  letter-spacing: 1px;
}

h1 {
  color: $primary !important;
  font-size: $h1-font-size * 1.5;
}

// .nav-item {
//   // @include media-breakpoint-up (md) {
//   //   font-size: 25px;
//   // }
//   transition: all 300ms ease;
//   letter-spacing: 0px;
//   &:hover {
//     letter-spacing: 3px;
//   }
// }

#header > .navbar {
  background-color: transparent;
  background: $white;
  color: $af-black;
  height: $calculated-navbar-height-sm;
  transition: all 0.2s ease-in-out;
  // font-size: 16px;
  //box-shadow: $box-shadow-lg;
  @include media-breakpoint-up(md) {
    height: $calculated-navbar-height;
    // padding-left: 2vw;
    // padding-right: 2vw;
  }
  .nav-item {
    @extend .ml-2;
    &.last {
      @extend .ml-1;
    }
    &.dropdown.show {
      .nav-link {
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: $af-yellow;
        color: $black;
      }
      .dropdown-menu {
        @extend .mb-2;
        margin-top: 0;
        border: 0;
        border-top-right-radius: 0 !important;
        .dropdown-item {
          text-align: right;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
    }
  }
  i.fa-user {
    font-weight: 300 !important;
  }
  .nav-link {
    font-weight: bold;
    color: $black;
    //background: rgba($color: $white, $alpha: 0.5);
    letter-spacing: 1.2px;
    @include media-breakpoint-down(md) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    &:hover,
    &:focus,
    &:active {
      border-radius: $btn-border-radius;
      background-color: $black;
      color: $white;
    }
    &.active {
      border-radius: $btn-border-radius;
    }
    &.primary {
      color: $af-yellow;
    }
  }
  .navbar-brand {
    padding: 0 10px;
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    }
    img {
      height: 2rem;
      width: auto;
      max-height: 2rem;
      transition: all 0.2s ease-in-out;
    }
    @include media-breakpoint-up(md) {
      img {
        height: 3.5rem;
        width: auto;
        max-height: 4.5rem;
        transition: all 0.2s ease-in-out;
      }
    }
  }
  .hamburger-inner:after,
  .hamburger-inner:before,
  .hamburger-inner {
    height: 1px;
    width: 25px;
    color: $primary !important;
    background-color: $primary !important;
  }
}

// EFFECTS FOR SCROLLING

body[data-scrolled] {
  #header > .navbar {
    height: $calculated-navbar-height-sm;
    background: $white;
    //background: linear-gradient(rgba($af-yellow, 0.7), rgba($af-yellow, 0.7));
    .navbar-brand {
      img {
        height: 2rem;
        width: auto;
        max-height: 2rem;
        transition: all 0.2s ease;
      }
    }
  }
  .below-main-navigation {
    opacity: 0;
    transition: all 0.2s ease;
  }
}

.main-menu .nav-link.hamburger {
  background-color: transparent !important;
}

// MOBILE MENU

#main-menu {
  background: transparent;
  .nav-item {
    flex-direction: column;
    justify-content: stretch;
    .nav-link {
      background-color: $black; //$light-milk;
      color: $white;
      margin-bottom: 0.4rem;
      font-weight: 900;
      text-transform: uppercase;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      &:hover,
      &.active {
        background-color: $af-yellow;
        color: $black;
      }
      &.primary {
        color: $af-yellow;
      }
    }
    .dropdown-menu,
    .nav-link {
      width: 100%;
    }
    &.dropdown.show {
      .nav-link {
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        background-color: $af-yellow;
        color: $black;
      }
      .dropdown-menu {
        @extend .mb-2;
        margin-top: 0;
        border: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        .dropdown-item {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
    }
  }
}

.secondary-menu .menu {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.offcanvas-collapse {
  top: $calculated-navbar-height + 1rem;
  @include media-breakpoint-down(sm) {
    top: $calculated-navbar-height-sm + 1rem;
  }
}

video {
  background: $dark;
}

#cart-menu,
#main-menu {
  font-size: 16px;
}
#main-menu {
  bottom: auto;
  overflow: visible;
}

.logo-container {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $black;
  pointer-events: none;
}

// .logo-name {
//   // font-size: 18px;
//   @include media-breakpoint-up(md) {
//     font-size: 40px;
//   }
//   font-weight: 900;
//   font-style: normal;
// }
// .logo-branch {
//   @extend .d-none;
//   @extend .d-md-inline;
//   font-size: 30px;
//   font-weight: 500;
//   font-style: normal;
//   color: rgba(0, 0, 0, 0.5);
//   position: relative;
//   padding-left: 30px;

//   &::before {
//     position: absolute;
//     content: '';
//     left: 15px;
//     top: 50%;
//     transform: translateY(-50%);
//     width: 3px;
//     height: 35px;
//     background-color: currentColor;
//   }
// }

// MAKIS IN DA HOOD

.simple-card {
  padding: 0;
  background: transparent;
  .card-title {
    font-size: $h4-font-size;
    color: $white;
  }
  .card-body {
    //padding-bottom: 0px;
    padding: 0;
    @extend .py-2;
  }
}

.product-header-image {
  border-radius: 0;
  max-height: none;
}

.product-detail-title {
  //font-weight: 400;
  font-style: normal;
  //font-size: 2rem;
  @include media-breakpoint-up(md) {
    //font-size: 55px;
  }

  position: relative;
  margin-top: 0;
  margin-bottom: map-get($spacers, 4);

  small  {
    font-family: $font-family-sans-serif;
    text-transform: none;
    color: $dark;
    font-size: 1.5rem;
  }

  // &::before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   left: 50%;
  //   bottom: map-get($spacers, 2) * -1;
  //   width: 85px;
  //   height: 3px;
  //   background-color: $black;
  //   transform: translateX(-50%);

  //   @include media-breakpoint-up(md) {
  //     left: 0;
  //     width: 200px;
  //     transform: translateX(0);
  //   }
  // }
}

// // MAKI 2020 OVERWRITES

// .maki-2020 {
//   box-shadow: $box-shadow;
//   background-color: $white;
//   &,
//   &:link,
//   &:hover,
//   &:focus,
//   &:active {
//     color: $card-color;
//     text-decoration: none;
//   }
//   flex: 1;
//   //box-shadow: none;
//   border-radius: 0.25rem;
//   overflow: hidden;
//   transition: all 0.4s ease;
//   border: 0;

//   .btn {
//   }

//   .maki-img {
//     margin-bottom: 6rem;
//   }

//   .maki-img,
//   .maki-img-hover {
//     transition: opacity 0.5s;
//   }

//   .maki-img-hover {
//     position: absolute;
//     top: 0;
//     left: 0;
//     opacity: 0;
//     transition: none;
//   }

//   &:hover {
//     // .maki-img {
//     //   opacity: 0.75;
//     // }
//     box-shadow: $box-shadow-xl;
//     //transform: scale(1.02) translateZ(0) perspective(1px);
//     img {
//       //filter: brightness(1.05);
//     }
//     .maki-img-hover {
//       opacity: 1;
//     }
//   }

//   .card-body {
//     //background: linear-gradient(180deg, rgba($af-black3, 0.0), rgba($af-black3, 1.0));
//     background: linear-gradient(180deg, rgba($af-black2, 0.3), rgba($af-black, 0.9));

//     backdrop-filter: none;
//     text-align: left !important;

//     .card-title {
//       color: $white;
//       span.title {
//         font-size: $h5-font-size;
//         color: $af-yellow;
//         max-width: 100%;
//         width: 100%;
//         display: block;
//         @extend .text-truncate;
//         @extend .mb-1;
//       }
//     }

//     text-align: center;
//     position: relative;
//     transition: box-shadow 0.2s ease;

//     .sub-title {
//       //display: none;
//       @extend .text-muted;
//     }

//     .super-title {
//       font-size: 40%;
//       @extend .mb-1;
//     }

//     .prices {
//       @extend .d-flex;
//       @extend .justify-content-end;
//       @extend .text-right;
//       margin-top: 0.5rem;
//       span.price {
//         font-weight: bold;
//       }
//       span.former-price {
//         // seems like we have not the latest bs version?!
//         text-decoration: line-through;
//       }
//     }

//     small  {
//       font-family: $font-family-sans-serif;
//       text-transform: none;
//     }
//   }

//   .card-img-overlay {
//     top: auto;
//     padding: 0;
//     // max-height: 100%;
//     // overflow-y: auto;
//   }

//   &:hover,
//   &:focus,
//   &:active {
//     box-shadow: $box-shadow-lg-dark;
//     .card-body {
//       //box-shadow: $box-shadow-inverse-xl;
//       box-shadow: none;
//       background: linear-gradient(180deg, rgba($af-black2, 0.3), rgba($af-black, 0.9));
//       .card-title {
//         color: $white;
//         span.title {
//           white-space: normal;
//           text-overflow: unset;
//         }
//       }
//     }
//   }
// }

// // filter select options
// .react-select__menu,
// .react-select__control,
// .react-select__single-value,
// #fulltext {
//   color: rgb(255, 255, 255);
//   background: rgb(39, 39, 39) !important;
// }

// .react-select__option:active {
//   color: rgb(200, 200, 200);
//   background: rgb(39, 39, 39) !important;
// }

// .react-select__single-value {
//   color: rgb(252, 214, 0) !important;
// }

// .react-select__control--is-disabled {
//   color: rgb(200, 200, 200) !important;
//   background: rgb(0, 0, 0) !important;
// }

// // safari fix from-to dates on nigiri
// @include media-breakpoint-up(lg) {
//   .nigiri-date {
//     text-align: center;
//     flex-direction: column;
//   }

//   .nigiri-date * + * {
//     margin-top: -1rem;
//   }
// }

body.label-page {
  #main {
    margin-top: -$calculated-navbar-height;
  }
  .label-about {
    background-color: $white;
    position: relative;
  }
}

body.brand-listing-page {
  #brand-masonry {
    .row {
      @extend .no-gutters;
    }
    .maki-container {
      @extend .col-12;
      @extend .col-sm-6;
      @extend .col-md-4;
      @extend .col-xl-3;
      @extend .mb-0;
    }
  }
  .maki-2020 {
    &:hover {
      opacity: 0.8;
    }
    border: 0;
    text-align: left;
    .card-body {
      /* padding-left: 0;
      padding-right: 0; */
      background: transparent;
      backdrop-filter: none;
    }
    .card-title,
    .title {
      margin-bottom: 0;
      color: $primary;
    }
    img {
      margin-bottom: 0;
    }
    &:hover {
      box-shadow: none;
    }
  }
}

// checkout

section.checkout {
  a {
    color: $dark;
  }
}

// tags

// 3491 = "sale" tag
.maki-2020.category-id-3671 {
  &::after {
    //@extend .rounded-circle;
    content: 'EXCLUSIVE';
    background-color: $af-yellow;
    color: $black;
    padding: 0.25rem 0.75rem;
    font-weight: bold;
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 15px;
    right: -2px;
    transform: translate(-50%, -50%);
    transform: rotate(-4deg);
    // width: 80px;
    // height: 80px;
    //background: $af-yellow url('./assets/images/po.svg') no-repeat center center;
    //background-size: contain;
  }
  &:hover {
    &::after {
      opacity: 0.8;
    }
  }
}

.maki-2020.category-id-3672 {
  &::before {
    //@extend .rounded-circle;
    content: 'PRE-ORDER';
    background-color: $black;
    color: $af-yellow;
    padding: 0.25rem 0.75rem;
    font-weight: bold;
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 48px;
    right: -2px;
    transform: translate(-50%, -50%);
    transform: rotate(-4deg);
    // width: 80px;
    // height: 80px;
    //background: $af-yellow url('./assets/images/po.svg') no-repeat center center;
    //background-size: contain;
  }
  &:hover {
    &::after {
      opacity: 0.8;
    }
  }
}

// MAKI

.maki.card {
  background: transparent;
  &:hover {
    opacity: 0.8;
    //box-shadow: $box-shadow-xl;
    // transform: scale(101%);
    // transition: all 0.2s ease;
  }
  .card-title {
    text-align: left;
    display: block;
    @extend .text-truncate;
    justify-content: start !important;
    max-width: 90%;
    .title {
      font-size: $h2-font-size;
      font-weight: 600;
      @extend .text-truncate;
    }
    .super-title,
    .sub-title {
      font-size: smaller;
    }
  }
  transition: all 0.2s ease;
  .maki-img-container {
    border-radius: $border-radius;
    img {
      border-radius: $border-radius;
    }
  }
  .price {
    @extend .mt-1;
  }
}

.maki {
  .card-title {
    span.sub-title {
      font-size: 0.8em;
    }
  }
  .maki-img-container {
    overflow: hidden;
  }
  .maki-img {
    backface-visibility: hidden;
  }
  .maki-img-hover {
    transition: opacity 0.3s;
  }
  &:hover,
  &:focus {
    .maki-img {
      opacity: 1;
    }
  }
}

.masonry .maki-container {
  margin-bottom: 0;
  position: relative;

  .badge-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    .badge {
      font-size: 75%;
      font-weight: bold;
    }
  }

  .card-img-overlay {
    display: flex;
    align-items: flex-end;
  }
}

// CONTENT

body.landing-page {
  //background-color: $af-yellow;
  #header > .navbar {
    //background: linear-gradient(rgba($af-yellow, 0.7), transparent);
  }
  //margin-top: -$calculated-navbar-height;
  #main {
    margin-top: 0;
  }
  #footer {
    //background-color: $white;
  }
}

.cover-image {
  &:hover {
    /* transform: scale(1.02);
    box-shadow: $box-shadow-xl;
    transition: all 0.2s ease; */
  }
}

.eapps-instagram-feed-posts-grid-load-more,
.es-load-more-button,
.eapps-instagram-feed-posts-grid-load-more-visible {
  display: none !important;
}

// erste 3 Kacheln auf Release Seite in einer Reihe
.kachel-container div:nth-child(1),
.kachel-container div:nth-child(2),
.kachel-container div:nth-child(3) {
  @media (min-width: 576px) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
